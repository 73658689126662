import React from 'react';
import {
  Box,
  Card,
  Grid,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchDashboard,
  fetchUserById,
  queriesKeys,
} from '../../../http/Queries';
import { buildCurrency, TicketsStatusTable } from './TicketsStatusTable';
import { SalesByType } from './SalesByType';
import {
  downloadDetailSales,
  downloadDetailSalesTwo,
  downloadSalesByType,
} from '../../../http/Mutations';
import { LoadingButton } from '@mui/lab';

type ReportType = 'TicketsSold' | 'Totals';

const bgSelectedColor = 'rgba(96, 165, 250, 0.8)';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 25,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const iOS = () => {
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  );
};

const Dashboard: React.FC = () => {
  const [reportType, setReportType] = React.useState<ReportType>('TicketsSold');
  const qUserId = useQuery(queriesKeys.fetchUserById(), fetchUserById);
  const qDashboard = useQuery(queriesKeys.fetchDashboard(), fetchDashboard);

  const downloadHandler = (fileName: string, data: string) => {
    if (iOS()) {
      // Download file for ios
      const binary = Buffer.from(data, 'base64');
      const blob = new Blob([binary], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.target = '_blank';
      a.click();
      a.remove();
      return;
    }
    const url = `data:application/pdf;base64,${data}`;
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  };

  const mDownload = useMutation(downloadSalesByType, {
    onSuccess: (response: { data: string }) => {
      const fileName = 'reportebr.pdf';
      downloadHandler(fileName, response.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mDownloadDetail = useMutation(downloadDetailSales, {
    onSuccess: (response: { data: string }) => {
      const fileName = 'reportebr-detalle.pdf';
      downloadHandler(fileName, response.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mDownloadDetailTwo = useMutation(downloadDetailSalesTwo, {
    onSuccess: (response: { data: string }) => {
      const fileName = 'reportebr-detalle-2.pdf';
      downloadHandler(fileName, response.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  if (qDashboard.isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <h2>Resumen de ventas</h2>
      <Grid container rowGap={2}>
        <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
          <LinearProgressWithLabel
            variant='determinate'
            value={qDashboard.data?.percentageOf || 0}
          />
          <Box display='flex' justifyContent='space-between' px={2} pt={1}>
            <div>{buildCurrency(qDashboard.data?.total)}</div>
            <div>{buildCurrency(qDashboard.data?.expected)}</div>
          </Box>
        </Box>
        <Grid item container xs={12} columnGap={2}>
          <Card
            style={{
              padding: '.5rem',
              fontSize: '1.4rem',
              cursor: 'pointer',
              ...(reportType === 'TicketsSold' && {
                background: bgSelectedColor,
              }),
            }}
            onClick={() => setReportType('TicketsSold')}
          >
            <Box component='div'>Boletos expedidos</Box>
            <Box>{qDashboard.data?.ticketsSold}</Box>
          </Card>
          <Card
            style={{
              padding: '.5rem',
              fontSize: '1.4rem',
              cursor: 'pointer',
              ...(reportType === 'Totals' && {
                background: bgSelectedColor,
              }),
            }}
            onClick={() => setReportType('Totals')}
          >
            <div>Total $</div>
            <div>{buildCurrency(qDashboard.data?.total)}</div>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid item container gap={2} mb={2}>
            <LoadingButton
              variant='contained'
              loading={mDownload.isLoading}
              onClick={() => {
                mDownload.mutate();
              }}
            >
              Descargar reporte
            </LoadingButton>
            {qUserId.data?.email === 'fortunato@boletorapido.com' && (
              <>
                <LoadingButton
                  variant='contained'
                  loading={mDownloadDetail.isLoading}
                  onClick={() => {
                    mDownloadDetail.mutate();
                  }}
                >
                  Descargar reporte detallado
                </LoadingButton>
                <LoadingButton
                  variant='contained'
                  loading={mDownloadDetailTwo.isLoading}
                  onClick={() => {
                    mDownloadDetailTwo.mutate();
                  }}
                >
                  Descargar reporte detallado 2
                </LoadingButton>
              </>
            )}
          </Grid>
          <Paper sx={{ width: '100%', overflow: 'auto' }}>
            {reportType === 'TicketsSold' ? (
              <TicketsStatusTable email={qUserId.data?.email} />
            ) : (
              <SalesByType />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
