export * from './ticket-status.enum';
export * from './event-types.enum';
export * from './estados.enum';
export * from './timezones';
export * from './worker-type.enum';
export * from './payment-method.enum';
export * from './transaction-status.enum';
export * from './local-payment-method.enum';
export * from './event-status.enum';
export * from './link-status.enum';
export * from './link-type.enum';
export * from './discount-type.enum';
export * from './transfer-status.enum';

export enum WORKER_PERMISSIONS {
  GENERATE_COURTESY = 'GENERATE_COURTESY',
  CANCEL_TRANSACTION = 'CANCEL_TRANSACTION',
  SELL_BLOCKED_TICKETS = 'SELL_BLOCKED_TICKETS',
  PRINT_50 = 'PRINT_50',
}

export const PERMISSIONS_TRANSLATIONS = [
  {
    permission: WORKER_PERMISSIONS.GENERATE_COURTESY,
    label: 'Generar cortesía',
  },
  {
    permission: WORKER_PERMISSIONS.CANCEL_TRANSACTION,
    label: 'Cancelar transacción',
  },
] as const;
