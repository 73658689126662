import React from 'react';
import { Grid } from '@mui/material';
import { AxisOptions, Chart } from 'react-charts';
import { useQuery } from '@tanstack/react-query';
import { fetchSalesByDay, queriesKeys } from '../../http/Queries';
import { format } from 'date-fns';

type ChartType = {
  label: string;
  data: Array<{
    primary: string;
    secondary: number;
  }>;
};

type BarChartProps = {
  data: Array<ChartType>;
};

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const primaryAxis = React.useMemo<
    AxisOptions<typeof data[number]['data'][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof data[number]['data'][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );
  return (
    <Chart
      color='red'
      options={{
        data,
        primaryAxis,
        secondaryAxes,
      }}
    />
  );
};

const SalesByDay: React.FC = () => {
  const qSalesByDay = useQuery(queriesKeys.fetchSalesByDay(), fetchSalesByDay, {
    refetchInterval: 1000 * 10,
  });

  let mappedSticketsSold: Array<ChartType> = [];
  let mappedTotals: Array<ChartType> = [];

  if (qSalesByDay.isError) {
    return <div>Algo salio mal</div>;
  }

  if (qSalesByDay.isLoading) {
    return <div>Cargando...</div>;
  }

  if (qSalesByDay.data?.length) {
    mappedSticketsSold.push({
      label: 'Boletos vendidos',
      data: qSalesByDay.data.map((item) => ({
        primary: format(new Date(item.date), 'dd/MM/yyyy'),
        secondary: item.ticketsSold,
      })),
    });

    mappedTotals.push({
      label: 'Totales',
      data: qSalesByDay.data.map((item) => ({
        primary: format(new Date(item.date), 'dd/MM/yyyy'),
        secondary: +item.total,
      })),
    });
  }

  return (
    <Grid display='flex' flexDirection='column'>
      <h2>Ventas por diá </h2>
      <Grid display='flex' flexWrap='wrap'>
        <Grid width='100%' height={300}>
          <h3>Boletos vendidos</h3>
          {!!mappedSticketsSold.length && (
            <BarChart data={mappedSticketsSold} />
          )}
        </Grid>
        <Grid width='100%' height={300}>
          <h3>Totales</h3>
          {!!mappedTotals.length && <BarChart data={mappedTotals} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesByDay;
