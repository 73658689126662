import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FieldProps } from 'formik';

const createDate = (value: any) => {
  try {
    return value?.toString();
  } catch (err) {
    return '';
  }
};

export const DatePickerFormField: React.FC<FieldProps> = ({
  field,
  form,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={field.value}
        inputFormat='dd/MM/yyyy'
        onChange={(value) => {
          form.setFieldValue(field.name, createDate(value));
        }}
        renderInput={(params) => (
          <TextField fullWidth style={{ marginTop: '16px' }} {...params} />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};

const dateTimePickerStyles = makeStyles(() => ({
  container: {
    marginTop: '16px',
    marginBottom: '8px',
  },
}));
export const DateTimePickerFormField: React.FC<FieldProps> = ({
  field,
  form,
  ...props
}) => {
  const classes = dateTimePickerStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        className={classes.container}
        value={field.value}
        inputFormat='dd/MM/yyyy hh:mm aa'
        disablePast
        onChange={(uiPicker) => {
          form.setFieldValue(field.name, createDate(uiPicker));
        }}
        renderInput={(params) => (
          <TextField style={{ marginTop: '16px' }} fullWidth {...params} />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};
